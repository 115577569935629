body{
    font-family:" dancing-script";
}

.latest {
  margin-top: 20px;
}

.latestCards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}

.latestCard {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  background: #fff;
  width: 300px;
  border-radius: 10px;
  border: 1px solid #edecec;
  box-shadow: 0px 1px 8px 0px #00000040;
}
